<template>
  <the-header></the-header>
  <article-read></article-read>
</template>

<script>
import TheHeader from "../components/layout/ProfilePageHeader.vue";
import ArticleRead from "../components/ArticleReadComponents/ArticleRead.vue";

export default {
  components: {
    TheHeader,
    ArticleRead,
  },
};
</script>

<style scoped>

</style>
