<template>
  <div class="add-organiztion">
    <h2>Organization working for the cause</h2>
    <div class="add-organiztion__users">
      <div class="user-img">
        <img src="../../assets/images/bill.webp" alt="User"/>
      </div>
      <div class="user-details">
        <h4>Bill & Melinda Gates Foundation</h4>
        <a href="">View Profile</a>
      </div>
    </div>
    <div class="add-organiztion__users">
      <div class="user-img">
        <img src="../../assets/images/united-foundation.jpeg" alt="User"/>
      </div>
      <div class="user-details">
        <h4>United H.O.P.E Foundation</h4>
        <a href="">View Profile</a>
      </div>
    </div>
    <div class="invite-add-organiztion">
      <h2>Know any organization working for this cause?</h2>
      <p>Tag them here so that the world can know.</p>
      <button>Tag an Organization</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include reset-css;

.add-organiztion {
  margin-top: 6rem;

  h2 {
    font-size: 1.9rem;
    font-family: $font-primary-bold;
    margin-bottom: 0.8rem;
  }

  &__users {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;

    .user-img {
      width: 4rem;
      margin-right: 1.5rem;

      img {
        width: 100%;
        border-radius: 100%;
      }
    }

    .user-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h4 {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
        line-height: 2rem;
      }

      a {
        font-size: 1.2rem;
        color: #011e29;
        font-family: $font-primary-semibold;
      }
    }
  }

  .invite-add-organiztion {
    background-color: #f6f8ff;
    padding: 2rem;
    width: 100%;
    margin-top: 3rem;
    box-shadow: 0px 3px 6px #0000001a;

    h2 {
      font-size: 1.8rem;
      font-family: $font-primary-bold;
      margin-bottom: 0.6rem;
    }

    p {
      font-size: 1.49rem;
      font-family: $font-primary;
    }

    button {
      border: none;
      font-size: 1.6rem;
      font-family: $font-secondary;
      width: 100%;
      padding: 0.5rem 0;
      cursor: pointer;
      margin-top: 1.2rem;
      background-color: $color-primary;
      color: $color-white;
    }

    button:hover {
      opacity: 0.8;
    }
  }
}
</style>
